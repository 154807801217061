:root {
  --ngx-pickup-request-tiles-height: 136px;
}
$tiles-height: var(--ngx-pickup-request-tiles-height);
$app-actionBar-height: 40px;

.app {
  &[class*='--hasActionBar'] {
    margin-bottom: $app-actionBar-height + 1;

    .app-ActionBar {
      margin-bottom: -$app-actionBar-height;
    }
  }
}

.xpo-LtlConfirmEnvironmentDialog {
  .mat-input-element {
    background: 0 0;
    border: none;
    color: currentColor;
    font: inherit;
    margin: 0;
    max-width: 100%;
    outline: 0;
    padding: 0;
    text-align: inherit;
    vertical-align: bottom;
    width: 100%;
  }
}

.xpo-GridSettings-columnSelection .xpo-GridColumnSelection-columns .xpo-GridColumnSelection-columns-group {
  flex: 0 1;
}